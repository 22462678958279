@use "../config/" as *;

.faq {
  display: none;
}

.accordian {
  &-head {
    font-size: 1.8rem;
    text-align: left;
    padding-block: var(--space-2);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--space-2);

    &::after {
      content: "\e800";
      transition: 0.3s;
      font-family: $font-icon;
      transform-origin: center center;
    }

    &[aria-expanded="true"] {
      &::after {
        transform: rotate(180deg);
      }

      &~.accordian-body {
        padding-top: var(--space-3);
        margin-top: var(--space-3);
        border-top: 0.1rem solid var(--dark-grey);
        grid-template-rows: 1fr;
      }
    }
  }

  &-body {
    display: grid;
    overflow: hidden;
    grid-template-rows: 0fr;
    // border-top: .1rem solid var(--dark-grey);
    // margin-block: var(--space-4);
    transition: grid-template-rows 0.3s, padding-top 0.3s, margin-top 0.3s;

    p {
      font-size: 1.6rem;
      line-height: 1.8;
      overflow: hidden;
      margin: 0;
    }
  }

  &-items {
    margin: var(--space-6) 0;
    padding: var(--space-2) var(--space-6);
    border-radius: 1rem;
    background: var(--white);
    box-shadow: 0.1rem 0.1rem 1rem -0.7rem;
  }
}