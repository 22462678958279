@use './map' as *;
@use './variables' as *;
@each $font-name,
$font-data in $fonts {
    @each $font-family,
    $font-weight in $font-data {
        @font-face {
            font-family: #{$font-name};
            font-style: normal;
            font-display: swap;
            font-weight: #{$font-weight};
            src: local(#{$font-name}),
            url("../fonts/#{$font-family}.woff2") format("woff2"),
            url("../fonts/#{$font-family}.woff") format("woff");
        }
    }
}