@use "../config" as *;
// margin and padding 0 for all elements and border box
* {
  margin: 0;
  padding: 0;
  font-family: $font-primary;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
// base font size and scroll behaviour for smooth scroll animation
html {
  font-size: 10px;
  scroll-behavior: smooth;
}
// body
// 1. As a best practice, apply a default `background-color`.
// 2. As a best practice, apply a default `color` so that if text color changes just change body text color.
body {
  font-family: $font-primary, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal;
  font-size: 1.6rem;
  background-color: var(--body); //1
  color: var(--text); //2
}
hr {
  border-top: 0.1rem solid black;
}
small,
label {
  font-size: 1.2rem;
}
big {
  font-size: 1.6rem;
}
sub,
sup {
  font-size: 1rem;
}
h1 {
  font-size: 2.8rem;
  margin-bottom: 1rem;
}
h2 {
  font-size: 2.4rem;
  margin-bottom: 1rem;
}
h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
}
h4 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}
h5 {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}
h6 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}
p {
  margin-bottom: 1rem;
}
a {
  text-decoration: none;
  color: black;
  &:hover {
    text-decoration: none;
  }
}
ul,
ol {
  padding-left: 2rem;
}
a:not([href]):not([class]) {
  &,
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
blockquote {
  margin: 0 0 1rem;
}
button {
  border: none;
  color: black;
  background-color: transparent;
  &:not(:disabled) {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
}
textarea {
  resize: vertical; // 1
}
input {
  &:focus-within {
    outline: none;
  }
}
iframe {
  border: 0;
}
[hidden] {
  display: none;
}
@media (min-width: 768px) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.6rem;
  }
  h3 {
    font-size: 2.4rem;
  }
  h4 {
    font-size: 2rem;
  }
  h5 {
    font-size: 1.8rem;
  }
  h6 {
    font-size: 1.6rem;
  }
}
