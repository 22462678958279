@use "../config/" as *;

html {
  &.no-scroll {
    width: 100%;
    height: 100%;

    body {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
}

.none {
  display: none;
}

// flex
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// container
.container {
  margin: auto;
  max-width: var(--container);
}

// layout
.even-columns {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: var(--space-6);

  @include mq(lg) {
    grid-template-columns: repeat(2, 1fr);
  }
}

// common-head
.head-info {
  h5 {
    font-weight: 400;
    color: var(--dark-grey);
    display: flex;
    align-items: center;
    gap: var(--space-2);

    &::before {
      flex-shrink: 0;
      content: "";
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: var(--secondary);
    }
  }

  h2 {
    margin-bottom: var(--space-4);
    font-size: 2.4rem;

    @include mq(lg) {
      font-size: 3.4rem;
    }
  }
}

// tabs
.tab {
  display: flex;
  gap: var(--space-2);
  list-style: none;
  padding: 0;
  margin-block: var(--space-4);
  flex-wrap: wrap;

  &-anchor {
    display: block;
    padding: var(--space-1) var(--space-4);
    font-size: 1.8rem;
  }

  &-item {
    position: relative;
    margin-bottom: var(--space-4);

    &::after,
    &::before {
      content: "";
      position: absolute;
      bottom: -0.5rem;
      width: 0;
      height: 0.5rem;
      border-radius: 50vmax;
      transition: width 0.3s;
    }

    &::before {
      left: 0;
      background-color: var(--primary);
    }

    &::after {
      right: 0;
      background-color: var(--secondary);
    }

    &:hover,
    &.active {
      &::after {
        width: 50%;
      }

      &::before {
        width: 70%;
      }
    }
  }
}

.web {
  display: none;

  @include mq(xl) {
    display: block;
  }
}

.tablet {
  display: none;

  @include mq(md) {
    display: block;
  }

  @include mq(xl) {
    display: none;

    &.web {
      display: block;
    }
  }
}

.mob {
  display: block;

  @include mq(md) {
    &:not(.web, .tablet) {
      display: none;
    }
  }
}

.para {
  font-size: var(--para);
  margin-bottom: var(--space-4);
  font-weight: 500;

  @include mq(md) {
    margin-bottom: var(--space-8);
  }
}

img {
  width: 100%;
  display: block;
}

.text-center {
  text-align: center;
}

.common-space {
  padding: var(--space-6) var(--space-4);

  @include mq(lg) {
    padding-block: var(--space-10);
  }
}