@use './map' as *;
@mixin mq($breakpoint) {
    $size: map-get($map: $breakpoints, $key: $breakpoint );
    @media (min-width:$size) {
        @content;
    }
}
@mixin truncate($line: 6) {
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
    overflow: hidden;
}