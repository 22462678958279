/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'custom-icons';
      src: url('../font/custom-icons.svg?11350731#custom-icons') format('svg');
    }
  }
  */
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "custom-icons";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-down-open:before {
  content: "\e800";
}

/* '' */
.icon-left-open:before {
  content: "\e801";
}

/* '' */
.icon-right-open:before {
  content: "\e802";
}

/* '' */
.icon-up-open:before {
  content: "\e803";
}

/* '' */
.icon-cancel:before {
  content: "\e804";
}

/* '' */
.icon-down:before {
  content: "\e805";
}

/* '' */
.icon-left:before {
  content: "\e806";
}

/* '' */
.icon-right:before {
  content: "\e807";
}

/* '' */
.icon-up:before {
  content: "\e808";
}

/* '' */
.icon-group:before {
  content: "\e809";
}

/* '' */
.icon-globe:before {
  content: "\e80a";
}

/* '' */
.icon-star:before {
  content: "\e80b";
}

/* '' */
.icon-quote-svgrepo-com:before {
  content: "\e80c";
}

/* '' */
.icon-ok:before {
  content: "\e80d";
}

/* '' */
.icon-phone:before {
  content: "\e80e";
}

/* '' */
.icon-location:before {
  content: "\e80f";
}

/* '' */
.icon-mail-alt:before {
  content: "\f0e0";
}

/* '' */
.icon-instagram:before {
  content: "\f16d";
}

/* '' */
.icon-whatsapp:before {
  content: "\f232";
}

/* '' */
.icon-twitter:before {
  content: "\f309";
}

/* '' */
.icon-facebook:before {
  content: "\f30c";
}

/* '' */
.icon-linkedin:before {
  content: "\f318";
}

.icon-threads:before {
  content: "\e810";
}

/* '' */