@use "../config/" as *;

.about {
  overflow: hidden;

  @include mq(lg) {
    &-visual {
      padding-right: var(--space-6);
    }
  }

  &-visual {
    // background: url("../img/fea-2.webp") no-repeat center;
    background: url("../../img/about_bg.webp") no-repeat center;
    background-size: cover;
    display: grid;
    place-content: center;
    background-color: hsl(0deg 2.26% 18.25% / 89%);
    // background-color: #7e6f6fe3;
    background-blend-mode: hard-light;

    .card {
      background-color: var(--white);
      box-shadow: 0.1rem 0.1rem 2rem -1rem var(--dark-grey);
      padding: var(--space-4);
      border-radius: 2rem;

      &:hover {
        .icon {
          transform: scaleX(-1);
        }
      }

      @include mq(lg) {
        &:first-child {
          grid-row-end: span 2;
        }
      }

      .icon {
        width: 7rem;
        height: 7rem;
        aspect-ratio: 1/1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 3rem;
        opacity: 0.8;
        border-radius: 50%;
        margin-bottom: var(--space-6);
        transition: transform 0.3s;

        &::before {
          font-family: $font-icon;
        }

        &-1 {
          background-color: rgba($color: #fd346e, $alpha: 0.4);

          &::before {
            content: "\e80b";
          }
        }

        &-2 {
          background-color: rgba($color: #57c9da, $alpha: 0.4);

          &::before {
            content: "\e809";
          }
        }

        &-3 {
          background-color: rgba($color: #ffdc60, $alpha: 0.4);

          &::before {
            content: "\e80a";
          }
        }
      }

      h3 {
        font-size: 2rem;
        margin-bottom: var(--space-3);
      }

      p {
        line-height: 1.6;
        margin-bottom: 0;
        color: var(--dark-grey);
      }
    }

    .even-columns {
      align-items: center;
      gap: var(--space-4);
    }
  }

  &-data {
    .btn {
      --btn-hover-bg: var(--secondary);
      margin-top: var(--space-4);
    }

    p {
      line-height: 1.6;
      color: var(--dark-grey);
      @include truncate(12);
    }
  }
}