@use "../config/" as *;
.testimonials {
  color: var(--black);
  background-color: var(--grey);
  overflow: hidden;
  .head {
    margin-bottom: var(--space-6);
  }
  .swiper {
    overflow: unset;
  }
  .slide {
    &-col {
      width: 100%;
      flex-shrink: 0;
      position: relative;
      display: flex;
      flex-direction: column-reverse;
    }
    &-text {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(4.5px);
      border-radius: 10px;
      padding: var(--space-6);
      z-index: 2;
      padding-top: 7rem;
      p {
        font-size: 18px;
        line-height: 24px;
      }
      h2 {
        margin: 10px;
      }
    }
    &-img {
      width: 15rem;
      margin-bottom: -5rem;
      z-index: 2;
      position: relative;
      margin-left: 2rem;
      img {
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
      }
    }
  }
}
