@use "../config/" as *;
.hero {
  // background: var(--accent);
  background-image: url("../img/banner.webp");
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  isolation: isolate;
  color: var(--white);
  &::before {
    content: "";
    // background-color: hsla(0, 0%, 0%, 0.1);
    z-index: -1;
    position: absolute;
    inset: 0;
  }
  .center-content {
    padding: var(--space-6) var(--space-2);
    text-align: center;
    @include mq(md) {
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .logo {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 2rem;
    img {
      margin: 0 auto;
      width: 30rem;
      height: 12rem;
      object-fit: contain;
      filter: brightness(0) invert(1);
    }
  }
  .particles {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: -1;
  }
  .tech {
    &-list {
      gap: var(--space-4);
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    &-item {
      $size: 8rem;
      width: $size;
      height: $size;
      aspect-ratio: 1/1;
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }
  h2 {
    margin: 0 auto;
    max-width: 25ch;
    font-size: 2.4rem;
    @include mq(md) {
      font-size: 4rem;
      margin-inline: 20rem;
    }
  }

  //  particle animation
  @for $i from 1 through 31 {
    @keyframes particle-animation-#{$i} {
      0% {
        transform: translate3d(
            (random(110) * 1vw),
            (random(100) * 1vh),
            (random(400) * 1px)
          )
          rotate(random(90) + deg);
      }
      100% {
        transform: translate3d(
            (random(110) * 1vw),
            (random(100) * 1vh),
            (random(400) * 1px)
          )
          rotate(random(180) + deg);
      }
    }
    //fade in and out
    //apply keyframes to children of .particles - the circles
    //and make them a random size, color, and delay
    .particles span:nth-child(#{$i}) {
      animation: particle-animation-#{$i} 10s ease alternate infinite;
      $size: random(
          $limit: 20,
        ) +
        px;
      height: $size;
      width: $size;
      border-radius: 50%;
      background-color: #f9d020;
      animation-delay: -$i + s;
      display: block;
    }
  }
  //  particle animation
}
