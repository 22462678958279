@use "../config/" as *;
footer {
  background-color: var(--primary);
  color: var(--white);
  padding: var(--space-6);
  p {
    margin: 0;
    font-size: 1.8rem;
    text-align: center;
  }
}
