@use "../config/" as *;
.service {
  background-color: var(--grey);
  isolation: isolate;
  .btn {
    --btn-hover-bg: var(--secondary);
    padding-inline: var(--space-6);
  }
  .card {
    padding: var(--space-4);
    background-color: var(--white);
    border-radius: 2rem;
    // margin-bottom: var(--space-4);
    position: relative;
    &::before {
      content: "";
      position: absolute;
      bottom: -1rem;
      border-radius: 2rem;
      inset-inline: 0;
      margin: auto;
      width: 80%;
      height: 2.5rem;
      z-index: -1;
      background: linear-gradient(to right, var(--primary), var(--accent));
    }
    @include mq(lg) {
      display: flex;
      gap: var(--space-6);
      padding-block: var(--space-10);
      &::before {
        bottom: 0;
        transition: bottom 0.3s;
      }
      &:hover {
        &::before {
          bottom: -2rem;
        }
        .card-left {
          img {
            transform: translateY(-2rem);
          }
        }
      }
    }
    &-left {
      width: 11rem;
      flex-shrink: 0;
      margin-bottom: var(--space-6);
      img {
        transition: transform 0.3s;
        width: 10rem;
        height: 10rem;
        object-fit: contain;
      }
    }
    &-right {
      h3 {
        font-size: 2rem;
        font-weight: 400;
        margin-bottom: var(--space-4);
        @include mq(lg) {
          font-size: 3.4rem;
        }
      }
      p {
        line-height: 1.6;
        color: var(--dark-grey);
        @include truncate();
      }
      a {
        font-size: 1.6rem;
        display: flex;
        align-items: center;
        margin-top: var(--space-4);
        transition: color 0.3s;
        font-weight: 700;
        &::before {
          content: "\e807";
          font-size: inherit;
          font-family: $font-icon;
          margin-right: var(--space-2);
        }
        &:hover {
          color: var(--primary);
        }
      }
    }
  }
}
