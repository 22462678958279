@use "../config/" as *;

.contact {
  background-color: var(--grey);

  &-list {
    display: flex;
    flex-wrap: wrap;
  }

  &-img {
    width: clamp(25rem, 50%, 50rem);
    aspect-ratio: 1/1;
    margin-bottom: var(--space-6);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 2rem;
    }
  }

  &-foot {
    margin-top: auto;
  }

  &-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--primary);
    border-radius: 1rem;
    padding: var(--space-4);
    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 30rem;
      height: 30rem;
      border-radius: 50%;
      background-color: var(--secondary);
      transform: translate(50%, 50%);
    }

    .head-info {
      h2 {
        font-size: 3rem;
        color: vaR(--white);
      }

      h5 {
        color: var(--white);
      }
    }
  }

  &-data {
    width: 100%;
    padding: var(--space-4) 0;
    border-radius: 1rem;
    display: flex;
    gap: var(--space-2);
    transition: 0.5s;
    color: var(--white);

    .head {
      width: 5rem;
      aspect-ratio: 1/1;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .icon {
      font-family: $font-icon;
      font-size: 3rem;
    }

    h4 {
      font-size: 2rem;

      @include mq(lg) {
        font-size: 2.6rem;
      }
    }

    a {
      font-size: 1.6rem;
      color: inherit;
      opacity: 0.6;
      margin-right: var(--space-4);
    }
  }

  .social {
    &-list {
      display: flex;
      gap: var(--space-2);
      margin-block: var(--space-8) var(--space-4);
    }

    &-item {
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        padding: var(--space-2);
        color: var(--white);
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 50%;
        transition: 0.3s;

        &:hover {
          background-color: var(--secondary);
          color: var(--black);
        }
      }
    }
  }

  &-form {
    @include mq(lg) {
      h3 {
        font-size: 5rem;
        margin-bottom: var(--space-6);
      }
    }

    label {
      font-size: 1.6rem;
      margin-bottom: var(--space-1);
      display: block;
    }

    .form {
      --btn-hover-bg: var(--secondary);
      &-error {
        color: var(--danger);
        &:empty {
          display: none;
        }
      }
      &-element {
        width: 100%;
        margin-bottom: var(--space-4);
      }

      &-control {
        font-size: 1.4rem;
        height: 6rem;
        padding: var(--space-4);
        width: 100%;
        border-radius: 1rem;
        border: 0.1rem solid var(--dark-grey);
        outline: none;
        transition: box-shadow 0.3s;
        background: transparent;

        &:focus {
          box-shadow: 0.2rem 0.4rem 1.5rem -0.7rem var(--black);
          border: 0.2rem solid var(--black);
        }
      }

      textarea {
        height: 20rem;
      }
    }
  }

  .radio {
    &-group {
      display: grid;
      margin-top: var(--space-4);
      gap: var(--space-4);
      grid-template-columns: repeat(auto-fit, 16rem);
    }

    &-control {
      gap: var(--space-2);
      display: flex;

      input {
        display: none;

        &:checked ~ .checkbox {
          background-color: var(--primary);
        }
      }

      .checkbox {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        flex-shrink: 0;
        border: 0.1rem solid var(--primary);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: var(--space-1);
        transition: 0.3s;

        &::before {
          content: "\e80d";
          font-family: $font-icon;
          color: var(--white);
          font-size: 1.2rem;
        }
      }
    }
  }
}
