@use "../config/" as *;

.team {
  --swiper-pagination-bottom: -3rem;
  display: none;
  color: var(--black);
  background-color: var(--grey);
  overflow: hidden;

  .head {
    margin-bottom: var(--space-6);
  }

  .swiper {
    overflow: unset;
  }

  .slide {
    &-col {
      width: 100%;
      flex-shrink: 0;
      position: relative;
      background: rgba(255, 255, 255, 0.2);
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(4.5px);
      border-radius: 10px;
      padding: var(--space-6);

      &::before {
        opacity: 0.5;
        content: "\E80C";
        font-family: $font-icon;
        font-size: 10rem;
        margin-bottom: var(--space-6);
      }
    }

    &-text {
      z-index: 2;

      p {
        font-size: 18px;
        line-height: 24px;
      }
    }

    &-img {
      width: 15rem;
      z-index: 2;
      margin-top: var(--space-4);
      position: relative;
      display: flex;
      align-items: center;
      gap: var(--space-4);

      img {
        height: 100%;
        width: 10rem;
        aspect-ratio: 1/1;
        flex-shrink: 0;
        border-radius: 50%;
        object-fit: cover;
      }

      h2 {
        font-size: 2rem;
        margin-bottom: 0;
      }

      h3 {
        font-size: 1.8rem;
      }
    }
  }
}