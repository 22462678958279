@use "map" as *;
@use "mixin" as *;
$font-version: 69117260;
$image-version: 1;
// font variable
$font-primary: DMSans;
$font-icon: custom-icons;
:root {
  //    colors variable generate
  @each $color-key, $color-value in $colors {
    --#{$color-key}: #{$color-value};
  }
  // space variable
  @for $space from 0 through 10 {
    --space-#{$space}: #{$space * 0.5rem};
  }
  // width height variables
  --container: 100%;
  --header-height: 8rem;
  --logo-width: 16rem;
  --logo-height: 5rem;
  --btn-height: 5rem;
  // font-sizes variables
  --logo-size: 2rem;
  --para: 1.8rem;
  --title-size: 2rem;
  --large-font: 3rem;
  // zindexs variables
  --zindex-header: 10;
}
@include mq(sm) {
  :root {
    --container: 54rem;
  }
}
@include mq(md) {
  :root {
    --logo-size: 4rem;
    --large-font: 8rem;
    --title-size: 3.6rem;
    --para: 2.4rem;
    --container: 72rem;
  }
}
@include mq(lg) {
  :root {
    --container: 96rem;
  }
}
@include mq(xl) {
  :root {
    --container: 114rem;
  }
}
@include mq(xxl) {
  :root {
    --container: 132rem;
  }
}
