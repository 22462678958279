@use "../config/" as *;
.product {
  overflow: hidden;

  .card {
    background-color: var(--grey);
    padding: var(--space-4);
    border-radius: 2rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 2rem;
    }
    &:not(:first-child) {
      .card-left {
        aspect-ratio: 1/1;
      }
      @include mq(lg) {
        display: flex;
        gap: var(--space-4);
        .card-left {
          width: 20rem;
          flex-shrink: 0;
        }
      }
    }
    &:first-child {
      .card-left {
        aspect-ratio: 16/9;
      }
      @include mq(lg) {
        grid-row-end: span 2;
        h3 {
          margin-top: var(--space-6);
          font-size: 3rem;
        }
        p {
          -webkit-line-clamp: 1;
        }
      }
    }
    h3 {
      font-size: 2rem;
      font-weight: 500;
      margin-block: var(--space-2);
      @include mq(lg) {
        margin-top: var(--space-1);
      }
    }
    p {
      margin-block: var(--space-4);
      line-height: 1.6;
      color: var(--dark-grey);
      @include truncate();
      -webkit-line-clamp: 2;
    }
    .btn {
      --btn-hover-bg: var(--secondary);
    }
  }
  .even-columns {
    flex-shrink: 0;
    width: 100%;
    transform: translateX(100%);
    transition: 0.5s;
    &.active {
      transform: translateX(0);
      order: -1;
    }
  }
  .tabs-container {
    display: flex;
    align-items: flex-start;
    gap: var(--space-4);
  }
  @include mq(md) {
    .body {
      overflow: hidden;
    }
    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
