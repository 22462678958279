@use "../config/" as *;
.btn-hamburger {
  width: 3rem;
  aspect-ratio: 1/1;
  flex-shrink: 0;
  .line {
    width: 100%;
    height: 0.3rem;
    margin-bottom: 0.5rem;
    background-color: var(--black);
    display: block;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.logo {
  &-wrap {
    display: flex;
    align-items: center;
    gap: var(--space-2);
    img {
      width: var(--logo-width);
      height: var(--logo-height);
      flex-shrink: 0;
      object-fit: cover;
    }
  }
}
header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--zindex-header);
  padding: var(--space-3) var(--space-2);
  height: var(--header-height);
  transition: background-color 0.3s;
  background-color: rgba($color: #fff, $alpha: 0.1);
  box-shadow: 0 1rem 1.5rem rgba($color: black, $alpha: 0.2);

  @include mq(md) {
    padding-inline: var(--space-6);
  }
  &.sticky {
    background-color: var(--white);
    box-shadow: 0 1rem 1.5rem rgba($color: black, $alpha: 0.2);
  }
  &:not(.sticky) {
    .logo {
      filter: brightness(0) invert(1);
    }
    a,
    nav .list-anchor {
      color: var(--white);
      &:hover {
        color: var(--accent);
      }
    }
    .line {
      background-color: var(--white);
    }
  }
  .btn-wrap {
    margin-inline: auto var(--space-4);
    @include mq(md) {
      display: flex;
      gap: var(--space-3);
    }
    .btn-fill {
      text-align: center;
      --btn-hover-bg: var(--secondary);
    }
  }
}
.menu-wrap {
  padding-block: var(--space-8);
  @include mq(xl) {
    padding: 0 var(--space-4);
  }
}
.mobile-nav {
  position: fixed;
  top: 0;
  right: -100%;
  max-width: 30rem;
  width: 100%;
  transition: right 0.5s;
  background-color: var(--black);
  height: 100%;
  overflow: auto;
  padding: var(--space-3) var(--space-5);
  &::before {
    content: "";
    z-index: -1;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    pointer-events: none;
    opacity: 0;
  }
  .logo-wrap {
    &::before {
      filter: invert(1);
      background-position: left top;
    }
  }
  &-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &.active {
    right: 0;
    &::before {
      opacity: 1;
    }
  }
  .logo {
    color: var(--white);
  }
  .btn-group {
    .btn {
      --hover-bg: transparent;
      --btn-hover-bg: var(--accent);
      --color: var(--dark-grey);
      --hover-color: var(--black);
      --btn-border: var(--dark-grey);
      --hover-border: var(--accent);
      width: 100%;
      margin-block: var(--space-3);
      &:last-child {
        width: 80%;
      }
    }
  }
  .btn-close {
    font-size: 0;
    &::before {
      content: "\e804";
      font: 2rem $font-icon;
      color: var(--white);
    }
  }
}
.site-nav {
  &,
  ul {
    list-style: none;
    padding: 0;
  }
  .list {
    &-anchor {
      width: 100%;
      text-align: left;
      display: block;
      text-transform: uppercase;
      padding-block: var(--space-4);
      font-weight: 700;
      color: inherit;
      font-size: 1.4rem;
      &-btn {
        display: flex;
        justify-content: space-between;
        gap: var(--space-2);
        &::after {
          content: "\e800";
          font-family: $font-icon;
          transition: transform 0.3s;
        }
        &[aria-expanded="true"] {
          &::after {
            transform: rotate(180deg);
          }
          & ~ .subnav {
            display: block;
          }
        }
      }
      @include mq(xl) {
        padding: var(--space-2);
      }
    }
    &-item {
      position: relative;
      color: var(--dark-grey);
      padding-left: var(--space-4);
      transition: 0.3s;
      &:not(:last-child) {
        border-bottom: 0.1rem solid rgba($color: #fff, $alpha: 0.1);
        @include mq(md) {
          border: 0;
        }
      }
      &.active,
      &:hover {
        color: var(--secondary) !important;
      }
      @include mq(xl) {
        width: max-content;
      }
    }
  }
  .subnav {
    border-top: 0.1rem solid rgba($color: #fff, $alpha: 0.1);
    display: none;
  }
  @include mq(xl) {
    display: flex;
    .subnav {
      position: absolute;
      top: 100%;
      background-color: var(--white);
      border-radius: 0 0 1rem 1rem;
      padding-inline: var(--space-4);
      box-shadow: 0.1rem 1rem 1.5rem -0.5rem var(--dark-grey);
      .list {
        &-anchor {
          padding: var(--space-4) 0;
        }
        &-item {
          &:not(:first-child) {
            border-top: 0.1rem solid var(--dark-grey);
          }
        }
      }
    }
  }
}
