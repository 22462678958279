@use "../config/" as *;
// buttons
.btn {
  padding: 1em 2em;
  // height: var(--btn-height);
  // line-height: var(--btn-height);
  display: inline-block;
  border-radius: 1rem;
  font-size: var(--btn-font, 1.4rem);
  font-weight: 700;
  border: 0.1rem solid var(--btn-border, transparent);
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  isolation: isolate;
  @include mq(lg) {
    --btn-font: 1.6rem;
  }
  &:disabled,
  &.disabled {
    opacity: 0.7;
    pointer-events: none;
  }
  &::after {
    top: 0;
    left: 0;
  }
  &::before {
    bottom: 0;
    right: 0;
  }
  &::before,
  &::after {
    content: "";
    width: 0;
    height: 50%;
    position: absolute;
    background-color: var(--btn-hover-bg, transparent);
    transition: width 0.3s;
    z-index: -1;
  }
  &:hover {
    &::before,
    &::after {
      width: 100%;
    }
  }
  &-fill {
    background: var(--btn-bg, var(--primary));
    color: var(--btn-color, var(--white));
    &:hover {
      background-color: var(--hover-bg, var(--secondary));
      color: var(--hover-color, var(--white));
    }
  }
  &-outline {
    color: var(--color, var(--black));
    border-color: var(--btn-border, var(--grey));
    &:hover {
      background-color: var(--hover-bg, var(--secondary));
      color: var(--hover-color, var(--white));
      border-color: var(--hover-border, var(--secondary));
    }
  }
  &:focus {
    box-shadow: 0.2rem 0.2rem 1.5rem var(--secondary);
  }
}
