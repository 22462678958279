@charset "UTF-8";
:root {
  --primary: #3184d7;
  --primary-dark: #25629e;
  --secondary: #16538f;
  --accent: #57c9da;
  --pink: #fd346e;
  --footerbg: #333442;
  --text: #292930;
  --body: #fff;
  --info: #2ae9e9;
  --success: #39e92a;
  --warning: #e9bf2a;
  --danger: #e92a2a;
  --black: #292930;
  --white: #fff;
  --grey: #f6f6f6;
  --medium-grey: #e9e9e9;
  --dark-grey: #8a879f;
  --space-0: 0rem;
  --space-1: 0.5rem;
  --space-2: 1rem;
  --space-3: 1.5rem;
  --space-4: 2rem;
  --space-5: 2.5rem;
  --space-6: 3rem;
  --space-7: 3.5rem;
  --space-8: 4rem;
  --space-9: 4.5rem;
  --space-10: 5rem;
  --container: 100%;
  --header-height: 8rem;
  --logo-width: 16rem;
  --logo-height: 5rem;
  --btn-height: 5rem;
  --logo-size: 2rem;
  --para: 1.8rem;
  --title-size: 2rem;
  --large-font: 3rem;
  --zindex-header: 10;
}

@media (min-width: 576px) {
  :root {
    --container: 54rem;
  }
}
@media (min-width: 768px) {
  :root {
    --logo-size: 4rem;
    --large-font: 8rem;
    --title-size: 3.6rem;
    --para: 2.4rem;
    --container: 72rem;
  }
}
@media (min-width: 992px) {
  :root {
    --container: 96rem;
  }
}
@media (min-width: 1200px) {
  :root {
    --container: 114rem;
  }
}
@media (min-width: 1400px) {
  :root {
    --container: 132rem;
  }
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local(DMSans), url("../fonts/DMSans-Regular.woff2") format("woff2"), url("../fonts/DMSans-Regular.woff") format("woff");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local(DMSans), url("../fonts/DMSans-Medium.woff2") format("woff2"), url("../fonts/DMSans-Medium.woff") format("woff");
}
@font-face {
  font-family: DMSans;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local(DMSans), url("../fonts/DMSans-Bold.woff2") format("woff2"), url("../fonts/DMSans-Bold.woff") format("woff");
}
@font-face {
  font-family: custom-icons;
  font-style: normal;
  font-display: swap;
  font-weight: normal;
  src: local(custom-icons), url("../fonts/custom-icons.woff2") format("woff2"), url("../fonts/custom-icons.woff") format("woff");
}
* {
  margin: 0;
  padding: 0;
  font-family: DMSans;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  font-family: DMSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal;
  font-size: 1.6rem;
  background-color: var(--body);
  color: var(--text);
}

hr {
  border-top: 0.1rem solid black;
}

small,
label {
  font-size: 1.2rem;
}

big {
  font-size: 1.6rem;
}

sub,
sup {
  font-size: 1rem;
}

h1 {
  font-size: 2.8rem;
  margin-bottom: 1rem;
}

h2 {
  font-size: 2.4rem;
  margin-bottom: 1rem;
}

h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

h4 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

h5 {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

h6 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

p {
  margin-bottom: 1rem;
}

a {
  text-decoration: none;
  color: black;
}
a:hover {
  text-decoration: none;
}

ul,
ol {
  padding-left: 2rem;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

blockquote {
  margin: 0 0 1rem;
}

button {
  border: none;
  color: black;
  background-color: transparent;
}
button:not(:disabled) {
  cursor: pointer;
}
button:focus {
  outline: none;
}

textarea {
  resize: vertical;
}

input:focus-within {
  outline: none;
}

iframe {
  border: 0;
}

[hidden] {
  display: none;
}

@media (min-width: 768px) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.6rem;
  }
  h3 {
    font-size: 2.4rem;
  }
  h4 {
    font-size: 2rem;
  }
  h5 {
    font-size: 1.8rem;
  }
  h6 {
    font-size: 1.6rem;
  }
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'custom-icons';
      src: url('../font/custom-icons.svg?11350731#custom-icons') format('svg');
    }
  }
  */
[class^=icon-]:before,
[class*=" icon-"]:before {
  font-family: "custom-icons";
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-down-open:before {
  content: "\e800";
}

/* '' */
.icon-left-open:before {
  content: "\e801";
}

/* '' */
.icon-right-open:before {
  content: "\e802";
}

/* '' */
.icon-up-open:before {
  content: "\e803";
}

/* '' */
.icon-cancel:before {
  content: "\e804";
}

/* '' */
.icon-down:before {
  content: "\e805";
}

/* '' */
.icon-left:before {
  content: "\e806";
}

/* '' */
.icon-right:before {
  content: "\e807";
}

/* '' */
.icon-up:before {
  content: "\e808";
}

/* '' */
.icon-group:before {
  content: "\e809";
}

/* '' */
.icon-globe:before {
  content: "\e80a";
}

/* '' */
.icon-star:before {
  content: "\e80b";
}

/* '' */
.icon-quote-svgrepo-com:before {
  content: "\e80c";
}

/* '' */
.icon-ok:before {
  content: "\e80d";
}

/* '' */
.icon-phone:before {
  content: "\e80e";
}

/* '' */
.icon-location:before {
  content: "\e80f";
}

/* '' */
.icon-mail-alt:before {
  content: "\f0e0";
}

/* '' */
.icon-instagram:before {
  content: "\f16d";
}

/* '' */
.icon-whatsapp:before {
  content: "\f232";
}

/* '' */
.icon-twitter:before {
  content: "\f309";
}

/* '' */
.icon-facebook:before {
  content: "\f30c";
}

/* '' */
.icon-linkedin:before {
  content: "\f318";
}

.icon-threads:before {
  content: "\e810";
}

/* '' */
.btn {
  padding: 1em 2em;
  display: inline-block;
  border-radius: 1rem;
  font-size: var(--btn-font, 1.4rem);
  font-weight: 700;
  border: 0.1rem solid var(--btn-border, transparent);
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  isolation: isolate;
}
@media (min-width: 992px) {
  .btn {
    --btn-font: 1.6rem;
  }
}
.btn:disabled, .btn.disabled {
  opacity: 0.7;
  pointer-events: none;
}
.btn::after {
  top: 0;
  left: 0;
}
.btn::before {
  bottom: 0;
  right: 0;
}
.btn::before, .btn::after {
  content: "";
  width: 0;
  height: 50%;
  position: absolute;
  background-color: var(--btn-hover-bg, transparent);
  transition: width 0.3s;
  z-index: -1;
}
.btn:hover::before, .btn:hover::after {
  width: 100%;
}
.btn-fill {
  background: var(--btn-bg, var(--primary));
  color: var(--btn-color, var(--white));
}
.btn-fill:hover {
  background-color: var(--hover-bg, var(--secondary));
  color: var(--hover-color, var(--white));
}
.btn-outline {
  color: var(--color, var(--black));
  border-color: var(--btn-border, var(--grey));
}
.btn-outline:hover {
  background-color: var(--hover-bg, var(--secondary));
  color: var(--hover-color, var(--white));
  border-color: var(--hover-border, var(--secondary));
}
.btn:focus {
  box-shadow: 0.2rem 0.2rem 1.5rem var(--secondary);
}

html.no-scroll {
  width: 100%;
  height: 100%;
}
html.no-scroll body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.none {
  display: none;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container {
  margin: auto;
  max-width: var(--container);
}

.even-columns {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: var(--space-6);
}
@media (min-width: 992px) {
  .even-columns {
    grid-template-columns: repeat(2, 1fr);
  }
}

.head-info h5 {
  font-weight: 400;
  color: var(--dark-grey);
  display: flex;
  align-items: center;
  gap: var(--space-2);
}
.head-info h5::before {
  flex-shrink: 0;
  content: "";
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: var(--secondary);
}
.head-info h2 {
  margin-bottom: var(--space-4);
  font-size: 2.4rem;
}
@media (min-width: 992px) {
  .head-info h2 {
    font-size: 3.4rem;
  }
}

.tab {
  display: flex;
  gap: var(--space-2);
  list-style: none;
  padding: 0;
  margin-block: var(--space-4);
  flex-wrap: wrap;
}
.tab-anchor {
  display: block;
  padding: var(--space-1) var(--space-4);
  font-size: 1.8rem;
}
.tab-item {
  position: relative;
  margin-bottom: var(--space-4);
}
.tab-item::after, .tab-item::before {
  content: "";
  position: absolute;
  bottom: -0.5rem;
  width: 0;
  height: 0.5rem;
  border-radius: 50vmax;
  transition: width 0.3s;
}
.tab-item::before {
  left: 0;
  background-color: var(--primary);
}
.tab-item::after {
  right: 0;
  background-color: var(--secondary);
}
.tab-item:hover::after, .tab-item.active::after {
  width: 50%;
}
.tab-item:hover::before, .tab-item.active::before {
  width: 70%;
}

.web {
  display: none;
}
@media (min-width: 1200px) {
  .web {
    display: block;
  }
}

.tablet {
  display: none;
}
@media (min-width: 768px) {
  .tablet {
    display: block;
  }
}
@media (min-width: 1200px) {
  .tablet {
    display: none;
  }
  .tablet.web {
    display: block;
  }
}

.mob {
  display: block;
}
@media (min-width: 768px) {
  .mob:not(.web, .tablet) {
    display: none;
  }
}

.para {
  font-size: var(--para);
  margin-bottom: var(--space-4);
  font-weight: 500;
}
@media (min-width: 768px) {
  .para {
    margin-bottom: var(--space-8);
  }
}

img {
  width: 100%;
  display: block;
}

.text-center {
  text-align: center;
}

.common-space {
  padding: var(--space-6) var(--space-4);
}
@media (min-width: 992px) {
  .common-space {
    padding-block: var(--space-10);
  }
}

.btn-hamburger {
  width: 3rem;
  aspect-ratio: 1/1;
  flex-shrink: 0;
}
.btn-hamburger .line {
  width: 100%;
  height: 0.3rem;
  margin-bottom: 0.5rem;
  background-color: var(--black);
  display: block;
}
.btn-hamburger .line:last-child {
  margin-bottom: 0;
}

.logo-wrap {
  display: flex;
  align-items: center;
  gap: var(--space-2);
}
.logo-wrap img {
  width: var(--logo-width);
  height: var(--logo-height);
  flex-shrink: 0;
  object-fit: cover;
}

header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--zindex-header);
  padding: var(--space-3) var(--space-2);
  height: var(--header-height);
  transition: background-color 0.3s;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.2);
}
@media (min-width: 768px) {
  header {
    padding-inline: var(--space-6);
  }
}
header.sticky {
  background-color: var(--white);
  box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.2);
}
header:not(.sticky) .logo {
  filter: brightness(0) invert(1);
}
header:not(.sticky) a,
header:not(.sticky) nav .list-anchor {
  color: var(--white);
}
header:not(.sticky) a:hover,
header:not(.sticky) nav .list-anchor:hover {
  color: var(--accent);
}
header:not(.sticky) .line {
  background-color: var(--white);
}
header .btn-wrap {
  margin-inline: auto var(--space-4);
}
@media (min-width: 768px) {
  header .btn-wrap {
    display: flex;
    gap: var(--space-3);
  }
}
header .btn-wrap .btn-fill {
  text-align: center;
  --btn-hover-bg: var(--secondary);
}

.menu-wrap {
  padding-block: var(--space-8);
}
@media (min-width: 1200px) {
  .menu-wrap {
    padding: 0 var(--space-4);
  }
}

.mobile-nav {
  position: fixed;
  top: 0;
  right: -100%;
  max-width: 30rem;
  width: 100%;
  transition: right 0.5s;
  background-color: var(--black);
  height: 100%;
  overflow: auto;
  padding: var(--space-3) var(--space-5);
}
.mobile-nav::before {
  content: "";
  z-index: -1;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
  opacity: 0;
}
.mobile-nav .logo-wrap::before {
  filter: invert(1);
  background-position: left top;
}
.mobile-nav-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobile-nav.active {
  right: 0;
}
.mobile-nav.active::before {
  opacity: 1;
}
.mobile-nav .logo {
  color: var(--white);
}
.mobile-nav .btn-group .btn {
  --hover-bg: transparent;
  --btn-hover-bg: var(--accent);
  --color: var(--dark-grey);
  --hover-color: var(--black);
  --btn-border: var(--dark-grey);
  --hover-border: var(--accent);
  width: 100%;
  margin-block: var(--space-3);
}
.mobile-nav .btn-group .btn:last-child {
  width: 80%;
}
.mobile-nav .btn-close {
  font-size: 0;
}
.mobile-nav .btn-close::before {
  content: "\e804";
  font: 2rem custom-icons;
  color: var(--white);
}

.site-nav,
.site-nav ul {
  list-style: none;
  padding: 0;
}
.site-nav .list-anchor {
  width: 100%;
  text-align: left;
  display: block;
  text-transform: uppercase;
  padding-block: var(--space-4);
  font-weight: 700;
  color: inherit;
  font-size: 1.4rem;
}
.site-nav .list-anchor-btn {
  display: flex;
  justify-content: space-between;
  gap: var(--space-2);
}
.site-nav .list-anchor-btn::after {
  content: "\e800";
  font-family: custom-icons;
  transition: transform 0.3s;
}
.site-nav .list-anchor-btn[aria-expanded=true]::after {
  transform: rotate(180deg);
}
.site-nav .list-anchor-btn[aria-expanded=true] ~ .subnav {
  display: block;
}
@media (min-width: 1200px) {
  .site-nav .list-anchor {
    padding: var(--space-2);
  }
}
.site-nav .list-item {
  position: relative;
  color: var(--dark-grey);
  padding-left: var(--space-4);
  transition: 0.3s;
}
.site-nav .list-item:not(:last-child) {
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.1);
}
@media (min-width: 768px) {
  .site-nav .list-item:not(:last-child) {
    border: 0;
  }
}
.site-nav .list-item.active, .site-nav .list-item:hover {
  color: var(--secondary) !important;
}
@media (min-width: 1200px) {
  .site-nav .list-item {
    width: max-content;
  }
}
.site-nav .subnav {
  border-top: 0.1rem solid rgba(255, 255, 255, 0.1);
  display: none;
}
@media (min-width: 1200px) {
  .site-nav {
    display: flex;
  }
  .site-nav .subnav {
    position: absolute;
    top: 100%;
    background-color: var(--white);
    border-radius: 0 0 1rem 1rem;
    padding-inline: var(--space-4);
    box-shadow: 0.1rem 1rem 1.5rem -0.5rem var(--dark-grey);
  }
  .site-nav .subnav .list-anchor {
    padding: var(--space-4) 0;
  }
  .site-nav .subnav .list-item:not(:first-child) {
    border-top: 0.1rem solid var(--dark-grey);
  }
}

footer {
  background-color: var(--primary);
  color: var(--white);
  padding: var(--space-6);
}
footer p {
  margin: 0;
  font-size: 1.8rem;
  text-align: center;
}

.hero {
  background-image: url("../img/banner.webp");
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  isolation: isolate;
  color: var(--white);
}
.hero::before {
  content: "";
  z-index: -1;
  position: absolute;
  inset: 0;
}
.hero .center-content {
  padding: var(--space-6) var(--space-2);
  text-align: center;
}
@media (min-width: 768px) {
  .hero .center-content {
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.hero .logo {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 2rem;
}
.hero .logo img {
  margin: 0 auto;
  width: 30rem;
  height: 12rem;
  object-fit: contain;
  filter: brightness(0) invert(1);
}
.hero .particles {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
}
.hero .tech-list {
  gap: var(--space-4);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.hero .tech-item {
  width: 8rem;
  height: 8rem;
  aspect-ratio: 1/1;
}
.hero .tech-item img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.hero h2 {
  margin: 0 auto;
  max-width: 25ch;
  font-size: 2.4rem;
}
@media (min-width: 768px) {
  .hero h2 {
    font-size: 4rem;
    margin-inline: 20rem;
  }
}
@keyframes particle-animation-1 {
  0% {
    transform: translate3d(41vw, 99vh, 213px) rotate(77deg);
  }
  100% {
    transform: translate3d(107vw, 42vh, 373px) rotate(29deg);
  }
}
.hero .particles span:nth-child(1) {
  animation: particle-animation-1 10s ease alternate infinite;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -1s;
  display: block;
}
@keyframes particle-animation-2 {
  0% {
    transform: translate3d(21vw, 16vh, 329px) rotate(38deg);
  }
  100% {
    transform: translate3d(82vw, 26vh, 243px) rotate(139deg);
  }
}
.hero .particles span:nth-child(2) {
  animation: particle-animation-2 10s ease alternate infinite;
  height: 3px;
  width: 3px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -2s;
  display: block;
}
@keyframes particle-animation-3 {
  0% {
    transform: translate3d(75vw, 43vh, 25px) rotate(5deg);
  }
  100% {
    transform: translate3d(32vw, 49vh, 150px) rotate(59deg);
  }
}
.hero .particles span:nth-child(3) {
  animation: particle-animation-3 10s ease alternate infinite;
  height: 19px;
  width: 19px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -3s;
  display: block;
}
@keyframes particle-animation-4 {
  0% {
    transform: translate3d(22vw, 5vh, 88px) rotate(13deg);
  }
  100% {
    transform: translate3d(53vw, 70vh, 69px) rotate(89deg);
  }
}
.hero .particles span:nth-child(4) {
  animation: particle-animation-4 10s ease alternate infinite;
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -4s;
  display: block;
}
@keyframes particle-animation-5 {
  0% {
    transform: translate3d(16vw, 95vh, 265px) rotate(48deg);
  }
  100% {
    transform: translate3d(42vw, 25vh, 317px) rotate(124deg);
  }
}
.hero .particles span:nth-child(5) {
  animation: particle-animation-5 10s ease alternate infinite;
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -5s;
  display: block;
}
@keyframes particle-animation-6 {
  0% {
    transform: translate3d(28vw, 33vh, 39px) rotate(21deg);
  }
  100% {
    transform: translate3d(63vw, 26vh, 45px) rotate(112deg);
  }
}
.hero .particles span:nth-child(6) {
  animation: particle-animation-6 10s ease alternate infinite;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -6s;
  display: block;
}
@keyframes particle-animation-7 {
  0% {
    transform: translate3d(61vw, 5vh, 385px) rotate(54deg);
  }
  100% {
    transform: translate3d(98vw, 91vh, 48px) rotate(58deg);
  }
}
.hero .particles span:nth-child(7) {
  animation: particle-animation-7 10s ease alternate infinite;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -7s;
  display: block;
}
@keyframes particle-animation-8 {
  0% {
    transform: translate3d(76vw, 83vh, 8px) rotate(56deg);
  }
  100% {
    transform: translate3d(89vw, 17vh, 389px) rotate(50deg);
  }
}
.hero .particles span:nth-child(8) {
  animation: particle-animation-8 10s ease alternate infinite;
  height: 19px;
  width: 19px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -8s;
  display: block;
}
@keyframes particle-animation-9 {
  0% {
    transform: translate3d(100vw, 88vh, 399px) rotate(59deg);
  }
  100% {
    transform: translate3d(22vw, 8vh, 73px) rotate(122deg);
  }
}
.hero .particles span:nth-child(9) {
  animation: particle-animation-9 10s ease alternate infinite;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -9s;
  display: block;
}
@keyframes particle-animation-10 {
  0% {
    transform: translate3d(110vw, 30vh, 166px) rotate(10deg);
  }
  100% {
    transform: translate3d(104vw, 55vh, 319px) rotate(106deg);
  }
}
.hero .particles span:nth-child(10) {
  animation: particle-animation-10 10s ease alternate infinite;
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -10s;
  display: block;
}
@keyframes particle-animation-11 {
  0% {
    transform: translate3d(57vw, 6vh, 1px) rotate(72deg);
  }
  100% {
    transform: translate3d(41vw, 19vh, 203px) rotate(116deg);
  }
}
.hero .particles span:nth-child(11) {
  animation: particle-animation-11 10s ease alternate infinite;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -11s;
  display: block;
}
@keyframes particle-animation-12 {
  0% {
    transform: translate3d(21vw, 61vh, 369px) rotate(9deg);
  }
  100% {
    transform: translate3d(41vw, 11vh, 341px) rotate(146deg);
  }
}
.hero .particles span:nth-child(12) {
  animation: particle-animation-12 10s ease alternate infinite;
  height: 1px;
  width: 1px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -12s;
  display: block;
}
@keyframes particle-animation-13 {
  0% {
    transform: translate3d(39vw, 58vh, 5px) rotate(39deg);
  }
  100% {
    transform: translate3d(107vw, 47vh, 46px) rotate(83deg);
  }
}
.hero .particles span:nth-child(13) {
  animation: particle-animation-13 10s ease alternate infinite;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -13s;
  display: block;
}
@keyframes particle-animation-14 {
  0% {
    transform: translate3d(83vw, 12vh, 136px) rotate(50deg);
  }
  100% {
    transform: translate3d(104vw, 23vh, 122px) rotate(1deg);
  }
}
.hero .particles span:nth-child(14) {
  animation: particle-animation-14 10s ease alternate infinite;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -14s;
  display: block;
}
@keyframes particle-animation-15 {
  0% {
    transform: translate3d(61vw, 55vh, 343px) rotate(7deg);
  }
  100% {
    transform: translate3d(41vw, 77vh, 45px) rotate(34deg);
  }
}
.hero .particles span:nth-child(15) {
  animation: particle-animation-15 10s ease alternate infinite;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -15s;
  display: block;
}
@keyframes particle-animation-16 {
  0% {
    transform: translate3d(100vw, 58vh, 164px) rotate(73deg);
  }
  100% {
    transform: translate3d(24vw, 2vh, 107px) rotate(98deg);
  }
}
.hero .particles span:nth-child(16) {
  animation: particle-animation-16 10s ease alternate infinite;
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -16s;
  display: block;
}
@keyframes particle-animation-17 {
  0% {
    transform: translate3d(86vw, 97vh, 50px) rotate(9deg);
  }
  100% {
    transform: translate3d(13vw, 60vh, 369px) rotate(126deg);
  }
}
.hero .particles span:nth-child(17) {
  animation: particle-animation-17 10s ease alternate infinite;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -17s;
  display: block;
}
@keyframes particle-animation-18 {
  0% {
    transform: translate3d(15vw, 75vh, 191px) rotate(82deg);
  }
  100% {
    transform: translate3d(98vw, 20vh, 182px) rotate(60deg);
  }
}
.hero .particles span:nth-child(18) {
  animation: particle-animation-18 10s ease alternate infinite;
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -18s;
  display: block;
}
@keyframes particle-animation-19 {
  0% {
    transform: translate3d(32vw, 7vh, 148px) rotate(70deg);
  }
  100% {
    transform: translate3d(51vw, 41vh, 16px) rotate(138deg);
  }
}
.hero .particles span:nth-child(19) {
  animation: particle-animation-19 10s ease alternate infinite;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -19s;
  display: block;
}
@keyframes particle-animation-20 {
  0% {
    transform: translate3d(7vw, 53vh, 288px) rotate(69deg);
  }
  100% {
    transform: translate3d(92vw, 34vh, 298px) rotate(89deg);
  }
}
.hero .particles span:nth-child(20) {
  animation: particle-animation-20 10s ease alternate infinite;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -20s;
  display: block;
}
@keyframes particle-animation-21 {
  0% {
    transform: translate3d(13vw, 77vh, 174px) rotate(9deg);
  }
  100% {
    transform: translate3d(72vw, 31vh, 166px) rotate(81deg);
  }
}
.hero .particles span:nth-child(21) {
  animation: particle-animation-21 10s ease alternate infinite;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -21s;
  display: block;
}
@keyframes particle-animation-22 {
  0% {
    transform: translate3d(28vw, 63vh, 304px) rotate(78deg);
  }
  100% {
    transform: translate3d(53vw, 67vh, 196px) rotate(49deg);
  }
}
.hero .particles span:nth-child(22) {
  animation: particle-animation-22 10s ease alternate infinite;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -22s;
  display: block;
}
@keyframes particle-animation-23 {
  0% {
    transform: translate3d(61vw, 70vh, 380px) rotate(67deg);
  }
  100% {
    transform: translate3d(19vw, 3vh, 138px) rotate(175deg);
  }
}
.hero .particles span:nth-child(23) {
  animation: particle-animation-23 10s ease alternate infinite;
  height: 19px;
  width: 19px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -23s;
  display: block;
}
@keyframes particle-animation-24 {
  0% {
    transform: translate3d(17vw, 34vh, 369px) rotate(10deg);
  }
  100% {
    transform: translate3d(64vw, 45vh, 267px) rotate(137deg);
  }
}
.hero .particles span:nth-child(24) {
  animation: particle-animation-24 10s ease alternate infinite;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -24s;
  display: block;
}
@keyframes particle-animation-25 {
  0% {
    transform: translate3d(103vw, 97vh, 242px) rotate(33deg);
  }
  100% {
    transform: translate3d(36vw, 18vh, 170px) rotate(88deg);
  }
}
.hero .particles span:nth-child(25) {
  animation: particle-animation-25 10s ease alternate infinite;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -25s;
  display: block;
}
@keyframes particle-animation-26 {
  0% {
    transform: translate3d(84vw, 54vh, 234px) rotate(60deg);
  }
  100% {
    transform: translate3d(69vw, 23vh, 166px) rotate(119deg);
  }
}
.hero .particles span:nth-child(26) {
  animation: particle-animation-26 10s ease alternate infinite;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -26s;
  display: block;
}
@keyframes particle-animation-27 {
  0% {
    transform: translate3d(110vw, 41vh, 158px) rotate(56deg);
  }
  100% {
    transform: translate3d(97vw, 75vh, 20px) rotate(166deg);
  }
}
.hero .particles span:nth-child(27) {
  animation: particle-animation-27 10s ease alternate infinite;
  height: 2px;
  width: 2px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -27s;
  display: block;
}
@keyframes particle-animation-28 {
  0% {
    transform: translate3d(81vw, 13vh, 44px) rotate(87deg);
  }
  100% {
    transform: translate3d(81vw, 93vh, 285px) rotate(97deg);
  }
}
.hero .particles span:nth-child(28) {
  animation: particle-animation-28 10s ease alternate infinite;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -28s;
  display: block;
}
@keyframes particle-animation-29 {
  0% {
    transform: translate3d(96vw, 19vh, 86px) rotate(17deg);
  }
  100% {
    transform: translate3d(31vw, 24vh, 351px) rotate(31deg);
  }
}
.hero .particles span:nth-child(29) {
  animation: particle-animation-29 10s ease alternate infinite;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -29s;
  display: block;
}
@keyframes particle-animation-30 {
  0% {
    transform: translate3d(34vw, 3vh, 282px) rotate(56deg);
  }
  100% {
    transform: translate3d(37vw, 20vh, 222px) rotate(131deg);
  }
}
.hero .particles span:nth-child(30) {
  animation: particle-animation-30 10s ease alternate infinite;
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -30s;
  display: block;
}
@keyframes particle-animation-31 {
  0% {
    transform: translate3d(30vw, 90vh, 135px) rotate(4deg);
  }
  100% {
    transform: translate3d(25vw, 14vh, 351px) rotate(134deg);
  }
}
.hero .particles span:nth-child(31) {
  animation: particle-animation-31 10s ease alternate infinite;
  height: 2px;
  width: 2px;
  border-radius: 50%;
  background-color: #f9d020;
  animation-delay: -31s;
  display: block;
}

.about {
  overflow: hidden;
}
@media (min-width: 992px) {
  .about-visual {
    padding-right: var(--space-6);
  }
}
.about-visual {
  background: url("../../img/about_bg.webp") no-repeat center;
  background-size: cover;
  display: grid;
  place-content: center;
  background-color: hsla(0, 2.26%, 18.25%, 0.89);
  background-blend-mode: hard-light;
}
.about-visual .card {
  background-color: var(--white);
  box-shadow: 0.1rem 0.1rem 2rem -1rem var(--dark-grey);
  padding: var(--space-4);
  border-radius: 2rem;
}
.about-visual .card:hover .icon {
  transform: scaleX(-1);
}
@media (min-width: 992px) {
  .about-visual .card:first-child {
    grid-row-end: span 2;
  }
}
.about-visual .card .icon {
  width: 7rem;
  height: 7rem;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  opacity: 0.8;
  border-radius: 50%;
  margin-bottom: var(--space-6);
  transition: transform 0.3s;
}
.about-visual .card .icon::before {
  font-family: custom-icons;
}
.about-visual .card .icon-1 {
  background-color: rgba(253, 52, 110, 0.4);
}
.about-visual .card .icon-1::before {
  content: "\e80b";
}
.about-visual .card .icon-2 {
  background-color: rgba(87, 201, 218, 0.4);
}
.about-visual .card .icon-2::before {
  content: "\e809";
}
.about-visual .card .icon-3 {
  background-color: rgba(255, 220, 96, 0.4);
}
.about-visual .card .icon-3::before {
  content: "\e80a";
}
.about-visual .card h3 {
  font-size: 2rem;
  margin-bottom: var(--space-3);
}
.about-visual .card p {
  line-height: 1.6;
  margin-bottom: 0;
  color: var(--dark-grey);
}
.about-visual .even-columns {
  align-items: center;
  gap: var(--space-4);
}
.about-data .btn {
  --btn-hover-bg: var(--secondary);
  margin-top: var(--space-4);
}
.about-data p {
  line-height: 1.6;
  color: var(--dark-grey);
  display: -webkit-box;
  -webkit-line-clamp: 12;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.service {
  background-color: var(--grey);
  isolation: isolate;
}
.service .btn {
  --btn-hover-bg: var(--secondary);
  padding-inline: var(--space-6);
}
.service .card {
  padding: var(--space-4);
  background-color: var(--white);
  border-radius: 2rem;
  position: relative;
}
.service .card::before {
  content: "";
  position: absolute;
  bottom: -1rem;
  border-radius: 2rem;
  inset-inline: 0;
  margin: auto;
  width: 80%;
  height: 2.5rem;
  z-index: -1;
  background: linear-gradient(to right, var(--primary), var(--accent));
}
@media (min-width: 992px) {
  .service .card {
    display: flex;
    gap: var(--space-6);
    padding-block: var(--space-10);
  }
  .service .card::before {
    bottom: 0;
    transition: bottom 0.3s;
  }
  .service .card:hover::before {
    bottom: -2rem;
  }
  .service .card:hover .card-left img {
    transform: translateY(-2rem);
  }
}
.service .card-left {
  width: 11rem;
  flex-shrink: 0;
  margin-bottom: var(--space-6);
}
.service .card-left img {
  transition: transform 0.3s;
  width: 10rem;
  height: 10rem;
  object-fit: contain;
}
.service .card-right h3 {
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: var(--space-4);
}
@media (min-width: 992px) {
  .service .card-right h3 {
    font-size: 3.4rem;
  }
}
.service .card-right p {
  line-height: 1.6;
  color: var(--dark-grey);
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.service .card-right a {
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  margin-top: var(--space-4);
  transition: color 0.3s;
  font-weight: 700;
}
.service .card-right a::before {
  content: "\e807";
  font-size: inherit;
  font-family: custom-icons;
  margin-right: var(--space-2);
}
.service .card-right a:hover {
  color: var(--primary);
}

.product {
  overflow: hidden;
}
.product .card {
  background-color: var(--grey);
  padding: var(--space-4);
  border-radius: 2rem;
}
.product .card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2rem;
}
.product .card:not(:first-child) .card-left {
  aspect-ratio: 1/1;
}
@media (min-width: 992px) {
  .product .card:not(:first-child) {
    display: flex;
    gap: var(--space-4);
  }
  .product .card:not(:first-child) .card-left {
    width: 20rem;
    flex-shrink: 0;
  }
}
.product .card:first-child .card-left {
  aspect-ratio: 16/9;
}
@media (min-width: 992px) {
  .product .card:first-child {
    grid-row-end: span 2;
  }
  .product .card:first-child h3 {
    margin-top: var(--space-6);
    font-size: 3rem;
  }
  .product .card:first-child p {
    -webkit-line-clamp: 1;
  }
}
.product .card h3 {
  font-size: 2rem;
  font-weight: 500;
  margin-block: var(--space-2);
}
@media (min-width: 992px) {
  .product .card h3 {
    margin-top: var(--space-1);
  }
}
.product .card p {
  margin-block: var(--space-4);
  line-height: 1.6;
  color: var(--dark-grey);
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}
.product .card .btn {
  --btn-hover-bg: var(--secondary);
}
.product .even-columns {
  flex-shrink: 0;
  width: 100%;
  transform: translateX(100%);
  transition: 0.5s;
}
.product .even-columns.active {
  transform: translateX(0);
  order: -1;
}
.product .tabs-container {
  display: flex;
  align-items: flex-start;
  gap: var(--space-4);
}
@media (min-width: 768px) {
  .product .body {
    overflow: hidden;
  }
  .product .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.contact {
  background-color: var(--grey);
}
.contact-list {
  display: flex;
  flex-wrap: wrap;
}
.contact-img {
  width: clamp(25rem, 50%, 50rem);
  aspect-ratio: 1/1;
  margin-bottom: var(--space-6);
}
.contact-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2rem;
}
.contact-foot {
  margin-top: auto;
}
.contact-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--primary);
  border-radius: 1rem;
  padding: var(--space-4);
  position: relative;
  overflow: hidden;
}
.contact-details::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30rem;
  height: 30rem;
  border-radius: 50%;
  background-color: var(--secondary);
  transform: translate(50%, 50%);
}
.contact-details .head-info h2 {
  font-size: 3rem;
  color: vaR(--white);
}
.contact-details .head-info h5 {
  color: var(--white);
}
.contact-data {
  width: 100%;
  padding: var(--space-4) 0;
  border-radius: 1rem;
  display: flex;
  gap: var(--space-2);
  transition: 0.5s;
  color: var(--white);
}
.contact-data .head {
  width: 5rem;
  aspect-ratio: 1/1;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-data .icon {
  font-family: custom-icons;
  font-size: 3rem;
}
.contact-data h4 {
  font-size: 2rem;
}
@media (min-width: 992px) {
  .contact-data h4 {
    font-size: 2.6rem;
  }
}
.contact-data a {
  font-size: 1.6rem;
  color: inherit;
  opacity: 0.6;
  margin-right: var(--space-4);
}
.contact .social-list {
  display: flex;
  gap: var(--space-2);
  margin-block: var(--space-8) var(--space-4);
}
.contact .social-item .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  padding: var(--space-2);
  color: var(--white);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  transition: 0.3s;
}
.contact .social-item .icon:hover {
  background-color: var(--secondary);
  color: var(--black);
}
@media (min-width: 992px) {
  .contact-form h3 {
    font-size: 5rem;
    margin-bottom: var(--space-6);
  }
}
.contact-form label {
  font-size: 1.6rem;
  margin-bottom: var(--space-1);
  display: block;
}
.contact-form .form {
  --btn-hover-bg: var(--secondary);
}
.contact-form .form-error {
  color: var(--danger);
}
.contact-form .form-error:empty {
  display: none;
}
.contact-form .form-element {
  width: 100%;
  margin-bottom: var(--space-4);
}
.contact-form .form-control {
  font-size: 1.4rem;
  height: 6rem;
  padding: var(--space-4);
  width: 100%;
  border-radius: 1rem;
  border: 0.1rem solid var(--dark-grey);
  outline: none;
  transition: box-shadow 0.3s;
  background: transparent;
}
.contact-form .form-control:focus {
  box-shadow: 0.2rem 0.4rem 1.5rem -0.7rem var(--black);
  border: 0.2rem solid var(--black);
}
.contact-form .form textarea {
  height: 20rem;
}
.contact .radio-group {
  display: grid;
  margin-top: var(--space-4);
  gap: var(--space-4);
  grid-template-columns: repeat(auto-fit, 16rem);
}
.contact .radio-control {
  gap: var(--space-2);
  display: flex;
}
.contact .radio-control input {
  display: none;
}
.contact .radio-control input:checked ~ .checkbox {
  background-color: var(--primary);
}
.contact .radio-control .checkbox {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  flex-shrink: 0;
  border: 0.1rem solid var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--space-1);
  transition: 0.3s;
}
.contact .radio-control .checkbox::before {
  content: "\e80d";
  font-family: custom-icons;
  color: var(--white);
  font-size: 1.2rem;
}

.team {
  --swiper-pagination-bottom: -3rem;
  display: none;
  color: var(--black);
  background-color: var(--grey);
  overflow: hidden;
}
.team .head {
  margin-bottom: var(--space-6);
}
.team .swiper {
  overflow: unset;
}
.team .slide-col {
  width: 100%;
  flex-shrink: 0;
  position: relative;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.5px);
  border-radius: 10px;
  padding: var(--space-6);
}
.team .slide-col::before {
  opacity: 0.5;
  content: "\e80c";
  font-family: custom-icons;
  font-size: 10rem;
  margin-bottom: var(--space-6);
}
.team .slide-text {
  z-index: 2;
}
.team .slide-text p {
  font-size: 18px;
  line-height: 24px;
}
.team .slide-img {
  width: 15rem;
  z-index: 2;
  margin-top: var(--space-4);
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--space-4);
}
.team .slide-img img {
  height: 100%;
  width: 10rem;
  aspect-ratio: 1/1;
  flex-shrink: 0;
  border-radius: 50%;
  object-fit: cover;
}
.team .slide-img h2 {
  font-size: 2rem;
  margin-bottom: 0;
}
.team .slide-img h3 {
  font-size: 1.8rem;
}

.testimonials {
  color: var(--black);
  background-color: var(--grey);
  overflow: hidden;
}
.testimonials .head {
  margin-bottom: var(--space-6);
}
.testimonials .swiper {
  overflow: unset;
}
.testimonials .slide-col {
  width: 100%;
  flex-shrink: 0;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}
.testimonials .slide-text {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.5px);
  border-radius: 10px;
  padding: var(--space-6);
  z-index: 2;
  padding-top: 7rem;
}
.testimonials .slide-text p {
  font-size: 18px;
  line-height: 24px;
}
.testimonials .slide-text h2 {
  margin: 10px;
}
.testimonials .slide-img {
  width: 15rem;
  margin-bottom: -5rem;
  z-index: 2;
  position: relative;
  margin-left: 2rem;
}
.testimonials .slide-img img {
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.faq {
  display: none;
}

.accordian-head {
  font-size: 1.8rem;
  text-align: left;
  padding-block: var(--space-2);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--space-2);
}
.accordian-head::after {
  content: "\e800";
  transition: 0.3s;
  font-family: custom-icons;
  transform-origin: center center;
}
.accordian-head[aria-expanded=true]::after {
  transform: rotate(180deg);
}
.accordian-head[aria-expanded=true] ~ .accordian-body {
  padding-top: var(--space-3);
  margin-top: var(--space-3);
  border-top: 0.1rem solid var(--dark-grey);
  grid-template-rows: 1fr;
}
.accordian-body {
  display: grid;
  overflow: hidden;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s, padding-top 0.3s, margin-top 0.3s;
}
.accordian-body p {
  font-size: 1.6rem;
  line-height: 1.8;
  overflow: hidden;
  margin: 0;
}
.accordian-items {
  margin: var(--space-6) 0;
  padding: var(--space-2) var(--space-6);
  border-radius: 1rem;
  background: var(--white);
  box-shadow: 0.1rem 0.1rem 1rem -0.7rem;
}